<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog" :retain-focus="false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Bilgilendirme Oluştur</h5>
            <button type="button" class="close" @click="CloseModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
                <input type="text" class="form-control" v-model="title" placeholder="Başlık" maxlength="500">
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <v-date-picker v-model="beginDate" mode="datetime" :minute-increment="10" :min-date="new Date()" is24hr>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-alt"></i></span></div>
                      <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="form-group col-6">
                <v-date-picker v-model="endDate" mode="datetime" :minute-increment="10" :min-date="beginDate" is24hr>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-alt"></i></span></div>
                      <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="form-group">
              <Editor v-model="content" api-key="rbhaa3tm3j8ba89f8a0xqv7zl42kpjbq8w602mok0h5xr6b4" :init="editorInit"></Editor>
            </div>
            <div class="form-row">
              <div class="form-group  col-8">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="isShowCloseButton" v-bind:id="'CloseButton'">
                    <label class="form-check-label" v-bind:for="'CloseButton'">
                      "Kapatma" butonunu aktif et.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="isShowRemindMeLaterButton" v-bind:id="'RemindMeLaterButton'">
                    <label class="form-check-label" v-bind:for="'RemindMeLaterButton'">
                      "Daha Sonra Hatırlat" butonunu aktif et.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="isShowInterestedButton" v-bind:id="'InterestedButton'">
                    <label class="form-check-label" v-bind:for="'InterestedButton'">
                      "İlgileniyorum" butonunu aktif et.
                    </label>
                  </div>
              </div>
              <div class="form-group  col-4">
                  <select class="form-control mb-2" v-model="status">
                    <option value="1">Aktif</option>
                    <option value="0">Pasif</option>
                  </select>
                  <div class="input-group">
                    <input type="number" min="0" max="10000" class="form-control" v-model="remindMeLaterDuration" placeholder="Hatılatma Süresi (Saat)">
                    <span class="input-group-text">Dk</span>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="SaveInformation()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {DateFormat} from "../../../helpers/date-convert-helper";
import Editor from '@tinymce/tinymce-vue';
export default {
  name: 'InformationStudentAddModal',
  emits:['ModalToggle'],
  components: { 
    Editor,
   },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    FormData:{
      type:Object,
      default:null
    },
    ModalName:{
      type:String,
      default:'InformationStudentAddModal'
    }
    
  },
  data() {
    return {
      id:'',
      title:'',
      beginDate:new Date(),
      endDate:'',
      content:'',
      isShowCloseButton:false,
      isShowRemindMeLaterButton:false,
      isShowInterestedButton:false,
      remindMeLaterDuration:23*60,
      status:0,
      submitLoading:false,
    }
  },
  computed:{
    editorInit(){
      return {menubar: false, statusbar: false, entity_encoding : 'raw', plugins:['code'], toolbar: ['forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | code']}
    }
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_CREATEINFORMATION","MEMBERPROFILE_UPDATEINFORMATION"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      CloseModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("ModalToggle", false);
      },
      SaveInformation(){
        this.submitLoading=true;
        if(!confirm('Bu bilgilendirmeyi kaydetmek istiyor musunuz')) {return;}
        let model = null;
        let process = null;
        if(this.FormData!=null){
          model = {
            "id":this.id,
            "title": this.title,
            "memberId": this.MemberId,
            "content": this.content,
            "beginDate": this.beginDate!=''?DateFormat(this.beginDate,'yyyy-mm-dd hh:MM:ss'):'',
            "endDate": this.endDate!=''?DateFormat(this.endDate,'yyyy-mm-dd hh:MM:ss'):'',
            "isShowCloseButton":/^true$/i.test(this.isShowCloseButton),
            "isShowRemindMeLaterButton":/^true$/i.test(this.isShowRemindMeLaterButton),
            "isShowInterestedButton":/^true$/i.test(this.isShowInterestedButton),
            "remindMeLaterDuration":this.remindMeLaterDuration,
            "priority":50,
            "status":parseInt(this.status,10)
          };
          process = this.MEMBERPROFILE_UPDATEINFORMATION(model);
        }else{
          model = {
            "memberId": this.MemberId,
            "title": this.title,
            "content": this.content,
            "beginDate": this.beginDate!=''?DateFormat(this.beginDate,'yyyy-mm-dd hh:MM:ss'):'',
            "endDate": this.endDate!=''?DateFormat(this.endDate,'yyyy-mm-dd hh:MM:ss'):'',
            "isShowCloseButton":/^true$/i.test(this.isShowCloseButton),
            "isShowRemindMeLaterButton":/^true$/i.test(this.isShowRemindMeLaterButton),
            "isShowInterestedButton":/^true$/i.test(this.isShowInterestedButton),
            "remindMeLaterDuration":this.remindMeLaterDuration,
            "priority":50,
            "status":parseInt(this.status,10)
          };
          process = this.MEMBERPROFILE_CREATEINFORMATION(model);
        }
        
        process.then((res)=>{
            this.$toast.success(res.data.description,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
            this.CloseModal();
        })
        .catch(error=>{
          if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
          this.submitLoading=false;
        });
      },
      InitFormForUpdate(){
        this.id = '';
        this.title = '';
        this.beginDate = new Date();
        this.endDate = '';
        this.content = '';
        this.isShowCloseButton = false;
        this.isShowRemindMeLaterButton=false;
        this.isShowInterestedButton=false;
        this.remindMeLaterDuration=23*60;
        this.status = 0;
        if(this.FormData!=null){
          this.id = this.FormData.id;
          this.title = this.FormData.title;
          this.beginDate = new Date(this.FormData.beginDate);
          this.endDate = new Date(this.FormData.endDate);
          this.content = this.FormData.content;
          this.isShowCloseButton = this.FormData.isShowCloseButton;
          this.isShowRemindMeLaterButton=this.FormData.isShowRemindMeLaterButton;
          this.isShowInterestedButton=this.FormData.isShowInterestedButton;
          this.remindMeLaterDuration=this.FormData.remindMeLaterDuration;
          this.status = this.FormData.status;
        }
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.InitFormForUpdate();
        this.submitLoading=false;
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
    window.$(document).on('focusin', function(e) {
      if (window.$(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
        e.stopImmediatePropagation();
      }
    });
    
  }
  
}
</script>