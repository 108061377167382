<template>
 <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-right mt-2 mb-2">
        <button class="btn btn-sm btn-primary" @click="NotificationAddModalToggle(true)" v-if="IsPermision(PermissionCodes.STUDENT21)">Yeni Bildirim Oluştur</button>
    </div>
    <div class="row border-bottom">
        <div class="col-3 text-bold">Tarih</div>
        <div class="col-7 text-bold">Başlık</div>
        <div class="col-2"></div>
    </div>
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div else>
        <div class="row border-bottom pt-1 pb-1 table-row" v-for="i in informations" :key="i">
            <div class="col-3 table-cell align-middle text-sm">
              <span class="d-block" v-if="i.beginDate!=''">{{DateFormat(i.beginDate)}}</span>
              <span class="d-block" v-if="i.endDate!=''">{{DateFormat(i.endDate)}}</span>
            </div>
            <div class="col-7 table-cell align-middle text-sm">
              <span class="badge badge-secondary">{{GetNotificationViewZone(i.notificationViewZone)}}</span>
              <div>{{i.title}}</div>
            </div>
            <div class="col-2 text-right table-cell align-middle">
              <button class="btn btn-sm btn-primary" v-if="IsPermision(PermissionCodes.STUDENT22)" @click="NotificationAddModalToggle(true,{id:i.id,title:i.title,content:i.content,beginDate:DateFormatForJavascript(i.beginDate),endDate:DateFormatForJavascript(i.endDate),isShowCloseButton:i.isShowCloseButton,isShowRemindMeLaterButton:i.isShowRemindMeLaterButton,isShowInterestedButton:i.isShowInterestedButton,remindMeLaterDuration:i.remindMeLaterDuration,status:i.status})">Düzelt</button>
            </div>
        </div>
    </div>
 </div>
 <InformationStudentAddModal :Visible="notifiationAddModalVisible" :MemberId="MemberId" :FormData="formData" @ModalToggle="NotificationAddModalToggle($event)" ></InformationStudentAddModal>
</template>
<style scoped></style>
<script>
import {mapActions} from "vuex";
import permissionMixins from "../../mixins/permissons-mixin";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {GetNotificationViewZone} from "../../helpers/enum-convert-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
import InformationStudentAddModal from "../MemberProfile/Modals/InformationStudentAddModal.vue";
export default {
  name: 'TabInformation',
  components: {
    InformationStudentAddModal
   },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      informations:[],
      isLoading:false,
      notifiationAddModalVisible:false,
      formData:null
    }
  },
  computed:{},
  methods:{
    ...mapActions(["MEMBERPROFILE_INFORMATIONLIST"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    DateFormat(value){
      return DateFormat(this.CalculateDateTimeByOffset(value),'dd.mm.yyyy hh:MM:ss');
    },
    DateFormatForJavascript(value){
      return DateFormat(this.CalculateDateTimeByOffset(value),'yyyy-mm-dd hh:MM:ss');
    },
    GetNotificationViewZone(value){
      return GetNotificationViewZone(value)
    },
    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_INFORMATIONLIST({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.informations = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
    NotificationAddModalToggle(value,data=null){
      this.formData = data;
      this.notifiationAddModalVisible = value;
      if(!value){this.GetInitData();}
    }
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  },
  mixins:[permissionMixins],
}
</script>